<header class="topbar">
    <nav class="d-flex top-navbar navbar-expand-md navbar-light">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
<!--        <div class="navbar-header">-->
<!--            <a class="navbar-brand" href="index.html">-->
<!--                &lt;!&ndash; Logo icon &ndash;&gt;-->
<!--                <b>-->
<!--                    &lt;!&ndash;You can put here icon as well // <i class="wi wi-sunset"></i> //&ndash;&gt;-->
<!--                    &lt;!&ndash; Dark Logo icon &ndash;&gt;-->
<!--                    <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo" />-->
<!--                    &lt;!&ndash; Light Logo icon &ndash;&gt;-->
<!--                    <img src="assets/images/logo-light-icon.png" alt="homepage" class="light-logo" />-->
<!--                </b>-->
<!--                &lt;!&ndash;End Logo icon &ndash;&gt;-->
<!--                &lt;!&ndash; Logo text &ndash;&gt;-->
<!--                <span>-->
<!--                    &lt;!&ndash; dark Logo text &ndash;&gt;-->
<!--                    <img src="assets/images/logo-text.png" alt="homepage" class="dark-logo" />-->
<!--                    &lt;!&ndash; Light Logo text &ndash;&gt;-->
<!--                    <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage" />-->
<!--                </span>-->
<!--            </a>-->
<!--        </div>-->
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav mr-auto mt-md-0">
                <!-- This is  -->
                <li (click)="toggleSidebar.emit()" class="nav-item m-l-10">
                    <a class="nav-link sidebartoggler waves-effect waves-dark" href="javascript:void(0)"><i
                            class="ti-menu"></i></a>
                </li>
                <!-- ============================================================== -->
                <!-- Comment -->
                <!-- ============================================================== -->
<!--                <li class="nav-item dropdown" ngbDropdown placement="bottom-left">-->
<!--                    <a ngbDropdownToggle class="nav-link text-muted text-muted waves-effect waves-dark"-->
<!--                        href="javascript:void(0)">-->
<!--                        <i class="mdi mdi-message"></i>-->
<!--                        <div class="notify">-->
<!--                            <span class="heartbit"></span>-->
<!--                            <span class="point"></span>-->
<!--                        </div>-->
<!--                    </a>-->
<!--                    <div class="dropdown-menu dropdown-menu-left mailbox" ngbDropdownMenu>-->
<!--                        <ul>-->
<!--                            <li>-->
<!--                                <div class="drop-title">Notifications</div>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <div class="message-center" [perfectScrollbar]="config">-->
<!--                                    &lt;!&ndash; Message &ndash;&gt;-->
<!--                                    <a href="#" *ngFor="let notification of notifications">-->
<!--                                        <div class="mail-contnet">-->
<!--                                            <h5>{{notification.title}}</h5>-->
<!--                                            <span class="mail-desc">{{notification.subject}}</span>-->
<!--                                            <span class="time">{{notification.time}}</span>-->
<!--                                        </div>-->
<!--                                    </a>-->
<!--                                </div>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </li>-->

            </ul>
            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
            <ul class="navbar-nav my-lg-0">
                <!-- ============================================================== -->
                <!-- Profile -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
                    <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark"
                        href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src="assets/images/logo.jpg" alt="user" class="profile-pic" />
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <ul class="dropdown-user">
<!--                            <li>-->
<!--                                <div class="dw-user-box">-->
<!--                                    <div class="u-img">-->
<!--                                        <img src="assets/images/logo.jpg" alt="user">-->
<!--                                    </div>-->
<!--                                    <div class="u-text">-->
<!--                                        <h4>Steave Jobs</h4>-->
<!--                                        <p class="text-muted">varun@gmail.com</p>-->
<!--                                        <a href="pages-profile.html" class="btn btn-rounded btn-danger btn-sm">View-->
<!--                                            Profile</a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </li>-->
<!--                            <li role="separator" class="divider"></li>-->
<!--                            <li>-->
<!--                                <a href="#">-->
<!--                                    <i class="ti-settings"></i> Account Setting</a>-->
<!--                            </li>-->
<!--                            <li role="separator" class="divider"></li>-->
                            <li>
                                <a href="#" (click)="logOut()">
                                    <i class="fa fa-power-off"></i> Logout</a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>