import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

export const routes: Routes = [
  {
    path: '',
    component: BlankComponent,
    children: [
      { path: '', redirectTo: '/authentication/login', pathMatch: 'full' },
      {
        path: 'authentication',
        loadChildren:
            () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ]
  },
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/starter', pathMatch: 'full' },
      {
        path: 'starter',
        loadChildren: './starter/starter.module#StarterModule'
      },
      {
        path: 'component',
        loadChildren: './component/component.module#ComponentsModule'
      },
      {
        path: 'cartypes',
        loadChildren: './CarTypes/CarTypes.module#CarTypesModule'
      },
      {
        path: 'appusers',
        loadChildren: './AppUsers/AppUsers.module#AppUsersModule'
      },
      {
        path: 'taxidriver',
        loadChildren: './TaxiDriver/TaxiDriver.module#TaxiDriverModule'
      },
      {
        path: 'rides',
        loadChildren: './Rides/Rides.module#RidesModule'
      },
      {
        path: 'coupons',
        loadChildren: './Coupons/Coupons.module#CouponsModule'
      },
      {
        path: 'payouts',
        loadChildren: './Payouts/Payouts.module#PayoutsModule'
      },
      {
        path: 'verification',
        loadChildren: './Verification/Verification.module#VerificationModule'
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'starter'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), NgbModule],
  exports: [RouterModule]
})
export class AppRoutingModule {}
